/* Root Variables for Colors and Fonts */
:root {
    --primary-color: #FFCB05;
    /* Adjusted to match the bright yellow from the app */
    --secondary-color: #FF5E5E;
    --background-color: #141414;
    /* Dark background similar to the mobile app */
    --text-color: #F9F9F9;
    /* Light text for contrast against dark background */
    --border-radius: 10px;
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    /* Stronger shadow for depth */
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Body Style */
body {
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
}

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: var(--background-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    margin-bottom: 20px;
    color: var(--text-color);
    position: relative;
    z-index: 1000;
}

.nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-logo {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: var(--primary-color);
}

.nav-hamburger {
    display: none;
    /* Hidden by default */
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 18px;
}

.hamburger {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: 0.4s;
    position: relative;
}

.hamburger.open {
    transform: rotate(45deg);
    background-color: var(--primary-color);
}

.hamburger.open::before {
    transform: rotate(90deg);
    top: 0;
    content: "";
}

.hamburger.open::after {
    transform: rotate(90deg);
    top: 0;
    content: "";
}

.hamburger::before,
.hamburger::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    transition: 0.4s;
}

.hamburger::before {
    top: -8px;
}

.hamburger::after {
    top: 8px;
}

.nav-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    transition: transform 0.3s ease;
}

.nav-links.active {
    transform: translateX(0);
    /* Visible when active */
}

.link {
    color: var(--text-color);
    text-decoration: none;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: var(--border-radius);
    transition: background-color 0.3s ease;
}

.link:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.active-link {
    background-color: var(--primary-color);
    color: var(--background-color);
    text-decoration: none;
}

.logout-button {
    background-color: var(--secondary-color);
    color: white;
    border: white 1px solid;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 16px;
}

.logout-button:hover {
    background-color: #D14949;
}

/* Common Container Styles */
.container,
.add-video-container,
.add-discount-container,
.dashboard-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    color: var(--text-color);
    box-shadow: var(--box-shadow);
}

.login-wrapper {
    height: 100vh;
    /* Full viewport height */
    display: flex;
    justify-content: center;
    /* Center horizontally */

    /* Center vertically */
}

.login-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    color: var(--text-color);
    box-shadow: var(--box-shadow);
}

h2 {
    font-size: 24px;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 20px;
}

/* Input Fields */
.video-input,
.discount-input,
.search-input,
.video-select,
.login-input {
    width: calc(100% - 20px);
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #333;
    border-radius: var(--border-radius);
    background-color: #222;
    color: var(--text-color);
    box-shadow: inset var(--box-shadow);
    font-size: 16px;
}

/* Category Checkboxes */
.categories-checkboxes {
    margin-bottom: 15px;
}

.category-checkbox {
    margin-bottom: 10px;
}

.category-checkbox label {
    margin-left: 10px;
    font-size: 16px;
    color: var(--text-color);
}

/* Buttons */
.add-video-button,
.add-discount-button,
.search-button,
.load-more-button,
.delete-video-button,
.delete-discount-button,
.login-button {
    padding: 12px 20px;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.login-button:hover,
.add-video-button:hover,
.add-discount-button:hover,
.search-button:hover,
.load-more-button:hover,
.delete-video-button:hover,
.delete-discount-button:hover {
    background-color: #E6B800;
}

.login-error {
    color: var(--secondary-color);
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

/* Discounts Page */
.discount-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.discount-item {
    width: 300px;
    border-radius: var(--border-radius);
    padding: 20px;
    background-color: #222;
    text-align: center;
    box-shadow: var(--box-shadow);
}

.discount-item img {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius);
    margin-bottom: 15px;
}

.discount-item h3,
.video-item h3 {
    font-size: 20px;
    margin: 15px 0;
    color: var(--text-color);
}

.discount-item p,
.video-item p {
    margin: 5px 0;
    font-size: 16px;
    color: #aaa;
}

.delete-discount-button,
.delete-video-button {
    background-color: var(--secondary-color);
    margin-top: 10px;
    border-radius: var(--border-radius);
}

/* Videos Page */
.accordion {
    cursor: pointer;
    background-color: var(--background-color);
    border: none;
    outline: none;
    padding: 15px 20px;
    width: 100%;
    text-align: left;
    font-size: 18px;
    transition: background-color 0.4s ease;
    margin-bottom: 10px;
    border-radius: var(--border-radius);
    color: var(--text-color);
}

.accordion:hover {
    background-color: #333;
}

.panel {
    padding: 15px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    background-color: #222;
    border-radius: var(--border-radius);
    color: var(--text-color);
}

.panel.open {
    max-height: 500px;
}

.categories-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.category-item {
    background-color: #333;
    padding: 15px;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: var(--text-color);
}

.video-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.video-item {
    background-color: #333;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    text-align: center;
    color: var(--text-color);
}

.video-item img {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius);
}

.pagination {
    text-align: center;
    margin-top: 20px;
}

/* Dashboard Page */
.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-header {
    background-color: var(--background-color);
    color: var(--primary-color);
    padding: 20px;
    border-radius: var(--border-radius);
    text-align: center;
}

.dashboard-stats {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.dashboard-card {
    background-color: #222;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    text-align: center;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    color: var(--text-color);
}

.dashboard-card h3 {
    color: var(--primary-color);
    font-size: 22px;
    margin-bottom: 10px;
}

.dashboard-card p {
    font-size: 18px;
    color: var(--text-color);
}

/* Sidebar */
.sidebar {
    width: 250px;
    background-color: #333;
    color: var(--text-color);
    height: 100vh;
    padding: 20px;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: var(--border-radius);
    transition: background-color 0.3s ease;
}

.sidebar a:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.save-button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 16px;
}

.save-button:hover {
    background-color: #E6B800;
}

.cancel-button {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 16px;
    margin-left: 20px;
}

.cancel-button:hover {
    background-color: #D14949;
}

.add-button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 16px;
}

.add-button:hover {
    background-color: #E6B800;
}

/* Responsive Adjustments */


/* Modal Overlay */
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.modal {
    background: white;
    padding: 20px;
    max-width: 500px;
    margin: auto;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    text-align: center;
}

/* Buttons inside Modal */
.confirm-button,
.cancel-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.confirm-button {
    background-color: var(--primary-color);
    color: white;
}

.cancel-button {
    background-color: var(--secondary-color);
    color: white;
}

.confirm-button:hover {
    background-color: #E6B800;
}

.cancel-button:hover {
    background-color: #D14949;
}

.page-settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.page-settings-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 10px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    border: 1px solid #333;
    border-radius: var(--border-radius);
    background-color: #222;
    color: var(--text-color);
}

.form-group textarea {
    resize: vertical;
}

.preview-image {
    margin-top: 10px;
    width: 200px;
    border-radius: var(--border-radius);
}

.learn-more-link {
    display: inline;
    margin-top: 5px;
    color: var(--primary-color);
    text-decoration: underline;
}

.learn-more-link:hover {
    color: var(--secondary-color);
    text-decoration: none;
}

.publish-button {
    background-color: var(--background-color);
    border-color: var(--primary-color);
    color: var(--primary-color);
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 20px;
}

.publish-button:hover {
    background-color: #E6B800;
    color: var(--background-color);
}

.toggle-status-button {
    padding: 8px 16px;
    background-color: #e2824a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.toggle-status-button:hover {
    background-color: #ff0000;
}

.user-item {
    padding: 20px;
    border: 1px solid #c1c1c1;
    border-radius: var(--border-radius);
    background-color: #2b2b2b;
    box-shadow: var(--box-shadow);
    margin-top: 20px;
}

.user-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--primary-color);
}

.user-item p {
    margin: 5px 0;
    font-size: 16px;
    color: #c5c5c5;
}

.logout-button {
    background-color: #2b2b2b;
    color: white;
    border: white 1px solid;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 16px;
}

.logout-button:hover {
    background-color: white;
    color: black;
}

.footer-container {
    background-color: #141414;
    /* Dark background similar to your existing styles */
    color: #f9f9f9;
    /* Light text for contrast */
    padding: 20px;
    text-align: center;
    border-top: 1px solid #333;
    /* Optional: a border to separate footer from the page */
}

.footer-content {
    margin-bottom: 10px;
}

.footer-content p {
    margin: 5px 0;
}

.footer-content a {
    color: #ffcb05;
    /* Match primary color or adjust as needed */
    text-decoration: none;
    font-weight: bold;
}

.footer-content a:hover {
    text-decoration: underline;
}

.footer-menu {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-link {
    color: #f9f9f9;
    text-decoration: none;
    font-size: 16px;
}

.footer-link:hover {
    text-decoration: underline;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f4f4;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.homepage-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background-color: #141414;
}

.login-button {
    background-color: #ffcb05;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #141414;
    cursor: pointer;
    font-weight: bold;
}

.login-button:hover {
    background-color: #e6b800;
}

.homepage-main {
    flex: 1;
    padding: 20px;
}

.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    color: white;
    position: relative;
}

.hero-content {
    max-width: 50%;
    color: white;
}

.hero-content h1 {
    font-size: 36px;
    color: #ffcb05;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 18px;
    color: white;
    margin-bottom: 20px;
}

.download-button {
    background-color: #ff5e5e;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.download-button:hover {
    background-color: #e14a4a;
}

.hero-image {
    max-width: 40%;
}

.aria-image {
    max-width: 100%;
    border-radius: 10px;
}

.mobile-image {
    display: none;
    /* Hidden by default */
}

.features-section {
    background-color: #1c1c1c;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #ffcb05;
}

.features {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.feature-item {
    flex: 1;
    min-width: 280px;
    background-color: #3c3c3c;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.feature-item h3 {
    color: #ffcb05;
    margin-bottom: 10px;
}

.feature-item p {
    color: white;
}

.homepage-footer {
    padding: 20px;
    text-align: center;
    background-color: #141414;
    color: #fff;
}

.remove-feature-button {
    background-color: transparent;
    color: var(--primary-color);
    /* yellowish color */
    border: 1px solid var(--primary-color);
    padding: 12px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 20px;
}

.remove-feature-button:hover {
    background-color: rgba(255, 203, 5, 0.1);
    /* Slight yellowish background on hover */
    color: var(--primary-color);
}

.settings-link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
}

.suspended-message {
    background-color: #ff5e5e;
    color: white;
    margin: 50px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}


@media (max-width: 768px) {

    .categories-list,
    .dashboard-stats {
        flex-direction: column;
    }

    .nav-hamburger {
        display: flex;
        /* Show hamburger on mobile */
    }

    .nav-links {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        transform: translateX(100%);
        /* Hidden by default */
        transition: transform 0.3s ease;
        z-index: 999;
    }

    .nav-links.active {
        transform: translateX(0);
        /* Slide in when active */
    }

    .link,
    .active-link,
    .logout-button {
        font-size: 24px;
        padding: 15px 30px;
    }

    .navbar {
        justify-content: space-between;
    }

    .container {
        padding: 0px;
        margin: 0px;
    }

    .homepage-main {
        padding: 0px;
    }

    .hero-section {
        flex-direction: column;
        align-items: center;

    }

    .hero-content {
        max-width: 100%;
        text-align: center;
    }

    .hero-image {
        display: none;
        /* Hide the side image on mobile */
    }

    .mobile-image {
        display: block;
        /* Show the image below the button on mobile */
        margin-top: 20px;
        text-align: center;
    }

    .aria-image {
        width: 80%;
        margin: 0 auto;
    }
}