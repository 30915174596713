/* Base styles for the video menu */
.video-menu {
    position: relative;
}

.video-menu-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
}

/* Submenu hidden by default */
.video-submenu {
    display: none !important;
    /* Ensure submenu is hidden initially */
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--background-color);
    border: 1px solid #333;
    padding: 10px 0;
    min-width: 200px;
    box-shadow: var(--box-shadow);
    z-index: 100;
    white-space: nowrap;
}

/* Show submenu on hover on desktop */
@media (min-width: 768px) {
    .video-menu:hover>.video-submenu {
        display: block !important;
        /* Force display on hover */
    }
}

/* Always show the submenu on mobile */
@media (max-width: 767px) {
    .video-submenu {
        display: block !important;
        /* Show submenu on mobile */
        position: static;
        padding-left: 20px;
    }
}

.video-submenu li {
    padding: 8px 20px;
    margin: 0;
}

.video-submenu li:hover {
    background-color: #333;
}

.login-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 16px;
    margin: 10px 0;
    /* Adjust the margin to fit well within the menu */
}

.login-button:hover {
    background-color: #E6B800;
}